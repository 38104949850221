import React from 'react';
import './Portfolio.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
    const[portfolio, setPortfolio] = useState([]);
    useEffect(()=> {
        fetch('portfolio.json')
        .then(res => res.json())
        .then(data => setPortfolio(data))
    },[])
    
  const navigate = useNavigate();
  const navigateToPortfolioDetail = (id) => {
    navigate(`/portfolio/${id}`);
  };
    return (
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
            {portfolio.map(({id, image, title, github, demo})=>{
                return (
                    <article key={id} className="portfolio__item">
                        <div className='portfolio__item-image'>
                            <img src={image} alt="" />
                        </div>
                        <h3>{title}</h3>
                        <div className="portfolio__item-cta">
                            <a className='btn' target="_blank" href={github}>Github</a>
                            <a className='btn btn-primary' target="_blank" href={demo}>Demo</a>
                            <button className='btn btn-primary' target="_blank" 
                            
                    onClick={() => navigateToPortfolioDetail(id)}
                            >View</button>
                        </div>
                    </article>
                )
                })}
            </div>
        </section>
    );
};

export default Portfolio;