import React from 'react';
import CTA from './CTA';
import './Header.css'
import me from '../../assets/jahangir.png'
import HeaderSocials from './HeaderSocials';

const Header = () => {
    return (
        <header>
            <div className="container header__container">
                <h5>Hello I'm</h5>
                <h1>Jahangir Alam</h1>
                <h5 className='text-light'>Full Stack Developer</h5>
                <CTA />
                <HeaderSocials />
                <div className="me">
                    <img src={me} alt="This is me" />
                </div>
                    <a href="#contact" className='scroll__down'>Scroll Down</a>
            </div>
        </header>
    );
};

export default Header;