import React from 'react';
import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {RiMessengerLine} from 'react-icons/ri'

const Contact = () => {
    return (
        <section id='contact'>
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>
            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineEmail className='contact__option-icon' />
                        <h4>Email</h4>
                        <h5>samraatjahangir@gmail.com</h5>
                        <a href="mailto:samraatjahangir@gmail.com">Send a Mesage</a>
                    </article>
                    <article className="contact__option">
                        <RiMessengerLine className='contact__option-icon' />
                        <h4>Messanger</h4>
                        <h5>Core Learners</h5>
                        <a href="https://m.me/developerjahangir">Send a Mesage</a>
                    </article>
                    <article className="contact__option">
                        <BsWhatsapp className='contact__option-icon' />
                        <h4>WhatsApp</h4>
                        <h5>+8801767275819</h5>
                        <a href="https://api.whatsapp.com/send?phone+8801767275819">Send a Mesage</a>
                    </article>
                </div>
                {/* End Contact Option */}
                <form action="mailto:samraatjahangir@gmail.com" method="post" enctype="text/plain">
                    <input type="text" name='name' placeholder='Your Name Here' required />
                    <input type="email" name='mail' placeholder='Your Email' required />
                    <textarea name="comment" id="message" rows="7"></textarea>
                    <button type='submit' className="btn btn-primary">Send Message</button>
                </form>
            </div>
        </section>
    );
};

export default Contact;