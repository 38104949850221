import React from 'react';
import { useParams } from 'react-router-dom';
import './PortfolioDetails.css'

const PortfolioDetails = () => {
    
  const { portfolioId } = useParams();

    return (
        <section className='container portfolioDetails__container'>
            <h1 className='text-center'>Project Details</h1>
            <hr style={{color: 'red', fontWeight: 'bolder'}} />
            <div className='portfolio__content'>
                <div>
                    <img src="portfolio1.png" alt="This is Portfolio one" />
                </div>
                <div>
                    <h1>Our Product id: {portfolioId}</h1>
                    <div className="portfolio__description">
                        <ul>
                            <li>Topic One</li>
                            <li>Topic Two</li>
                            <li>Topic One</li>
                            <li>Topic One</li>
                            <li>Topic One</li>
                            <li>Topic One</li>
                        </ul>
                    </div>
                    <div className="my__role">
                        <h3>My Role: </h3>
                        <p>This is my role here</p>
                    </div>
                    <div className="portfolio__action">
                        <button className="btn btn-primary">Github</button>
                        <button className="btn btn-primary">Demo</button>
                        <button className="btn btn-primary">Server Code</button>
                    </div>
                    <div className="technology__used">
                        <h3>Technology Used:</h3>
                        <p>Github, ReactJS, MongoDB, Firebase, ExpressJS, NodeJS, Tailwind, EmailJS.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PortfolioDetails;