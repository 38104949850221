import "./App.css";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Nav from "./components/Nav/Nav";
import Home from "./components/Home/Home";
import PortfolioDetails from "./components/PortfolioDetails/PortfolioDetails";
import Blog from "./components/Blog/Blog";
import NotFound from "./components/NotFound/NotFound";

function App() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="portfolio/:portfolioId" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
