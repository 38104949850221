import React from 'react';
import './Services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
    return (
        <section id='services'>
            <h5>What I Offer</h5>
            <h2>Services</h2>
            <div className="container services__container">



                <article className="service">
                    <div className="service__head">
                        <h3>Web Design</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Web Page Design and Redesign</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Web Page redesign and Custom Design</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Ecommerce Website Design any Layouts</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>React.js and Vue.js Web page design</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>WordPress and Shopify page design</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Responsive web design with Bootstrap and Tailwind</p>
                        </li>
                    </ul>
                </article>
                {/* End of UI/UX */}



                <article className="service">
                    <div className="service__head">
                        <h3>Web Development</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Web Application Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Mobile Back-end and API Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Ecommerce Web Design and Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>WordPress Website Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Shopify App Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Progressive Web App (PWA) Development</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>e-Learning Platform Development</p>
                        </li>
                    </ul>
                </article>
                {/* Web Development */}



                <article className="service">
                    <div className="service__head">
                        <h3>Web Page SEO and Marketing</h3>
                    </div>
                    <ul className='service__list'>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Title tag optimization. Title tags are what appear in search result</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Meta tag optimization</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Headings tag Manage and SEO Helpful</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Image optimization and alt tags keywords</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Mobile-friendliness</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon" />
                            <p>Content optimization and User experience.</p>
                        </li>
                    </ul>
                </article>
                {/* Content Creation */}
            </div>
        </section>
    );
};

export default Services;