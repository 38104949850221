import React from 'react';
import './Testimonials.css'


// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import AVATAR1 from '../../assets/avatar.png';
import AVATAR2 from '../../assets/avatar2.jpg';
import AVATAR3 from '../../assets/avatar3.jpg';
import AVATAR4 from '../../assets/avatar4.jpg';

const Testimonials = () => {
    const data = [
        {
            id: 1,
            avatar: AVATAR1,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
        {
            id: 2,
            avatar: AVATAR2,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
        {
            id: 3,
            avatar: AVATAR3,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
        {
            id: 4,
            avatar: AVATAR4,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
        {
            id: 5,
            avatar: AVATAR1,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
        {
            id: 1,
            avatar: AVATAR4,
            name: 'Jahangir Alam',
            review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo veniam debitis atque earum at. Eveniet quo eum facilis voluptatibus ipsam necessitatibus asperiores possimus aliquid, maxime, quia inventore totam laboriosam excepturi.'
        },
    ]
    return (
        <section id='testimonials'>
            <h5>Review from clients</h5>
            <h2>Testimonials</h2>

            <Swiper 
                className="container testimonials__container"
                // install Swiper modules
                modules={[Pagination]}
                spaceBetween={40}
                slidesPerView={1}
                pagination={{ clickable: true }}
            >
                {
                    data.map(({id, avatar, name, review}, index) => {
                        return (
                            <SwiperSlide 
                            key={index} className="testimonial">
                            <div className="client__avatar">
                                <img src={avatar} alt="Avatar One" />
                            </div>
                            <h5 className='client__name'>{name}</h5>
                                <small className="client__review">{review}</small>
                        </SwiperSlide>
                        )
                    })
                }
            </Swiper>

        </section>
    );
};

export default Testimonials;