import React from 'react';
import './About.css'
import Me from '../../assets/about-jahangir.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
const About = () => {
return (
<section id='about'>
    <h5>Get To Know</h5>
    <h2>About Me</h2>
    <div className="container about__container">
        <div className="about__me">
            <div className="about__me-image">
                <img src={Me} alt="ABout us" />
            </div>
        </div>
        <div className="about__content">
            <div className="about__cards">
                <article className='about__card'>
                    <FaAward className='about__icon' />
                    <h5>Experience</h5>
                    <small>3+ Year Working</small>
                </article>
                <article className='about__card'>
                    <FiUsers className='about__icon' />
                    <h5>Client</h5>
                    <small>200+ WorldWide</small>
                </article>
                <article className='about__card'>
                    <VscFolderLibrary className='about__icon' />
                    <h5>Projects</h5>
                    <small>80+ Completed</small>
                </article>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab natus fugit illo adipisci facilis esse error expedita enim rerum maxime quae nam, nemo minus perferendis, vero, nulla quidem earum consequatur!</p>
            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
    </div>
</section>
);
};

export default About;