import React from 'react';
import {Link} from 'react-router-dom'

const Blog = () => {
    return (
        <section className='container' style={{paddingTop: '50px'}}>
            <h1>Coming Soon. Please Wait and Stay With</h1>
            <Link to="/">Home</Link>
        </section>
    );
};

export default Blog;